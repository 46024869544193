@use "../../assets/sass/variables" as *;
@use "../../assets/sass/mixins" as *;
@use "../../assets/sass/reset" as *;

.services {
    display: flex;
    flex-direction: column;
    background-color: $white;
    padding: 30px;

    z-index: 2;
    position: relative;
    font-family: $secondary-font-family;

    &__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        padding: 10px;
        
        &__point {
            padding: 20px;
        }
    }
}




@media only screen and ((max-width: 650px)) {
    .services {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

@media only screen and ((min-width: 1110px)) {
    .services {
        padding-left: 60px;
        padding-right: 60px;
    }
}

@media only screen and ((max-width: 500px)) {
    .services {
        &__content {
            
            &__point {
                padding-left: 0px;
                padding-right: 0px;
            }
        }
    }
}

