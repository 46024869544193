@use "../../assets/sass/variables" as *;
@use "../../assets/sass/mixins" as *;
@use "../../assets/sass/reset" as *;

.footer {
    height: 100px;
    width: 100%;
    background: $white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    z-index: 3;
    color: $primary-color;
    border-top: double $primary-color 6px;
    grid-area: footer;
    font-weight: bold;

    &--dark {
        color: $white;
        background: $primary-color;
        border-top: double $white 6px;
        font-weight: normal;
    }
    
    &__text {
        width: 70%;
        font-family: $secondary-font-family;
        font-size: 12px;
    }
}

@media only screen and (max-width: 680px) {
    .footer {
        &__text {
            font-size: 10px;
            width: 80%;
        }
    }
}