@use "../../assets/sass/variables" as *;
@use "../../assets/sass/mixins" as *;
@use "../../assets/sass/reset" as *;

.home {
    overflow: hidden;
    &__panel-over {
        position: absolute;
    }
}
