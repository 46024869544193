@use "../../assets/sass/variables" as *;
@use "../../assets/sass/mixins" as *;
@use "../../assets/sass/reset" as *;

.photo-panel {
    width: 100%;
    position: relative;
    &__content {
        padding: 30px; 
    }
}

@media only screen and (max-width: 700px) {
    .photo-panel {
        width: 100vw;
        &__content {
            padding: 40px;
        }
    }
}
