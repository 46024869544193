@use "../../assets/sass/variables" as *;
@use "../../assets/sass/mixins" as *;
@use "../../assets/sass/reset" as *;

.contact-us {
    display: flex;
    justify-content: center;
    background-color: $primary-color;

    &__content {
        width: 100%;
        height:calc(100dvh - 76px);
        margin-top: 76px;
        display: grid;
        grid-template-areas: "email logo address"
                            "footer footer footer";
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 106px;

        

        &__email {
            background-color: $primary-color;
            grid-area: email;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            * {
                opacity: 1;
                animation-name: fadeIn;
                animation-iteration-count: 1;
                animation-timing-function: ease-in;
                animation-duration: 1.2s;
            }

            &__svg {
                width: 100px;
                fill: $white;
                padding-bottom: 20px;
            }
            &__text {
                color: $white;
                font-family: $secondary-font-family;
                font-size: 18px;
                padding: 10px;
                
                &:hover {
                    color: $sagey-grey;
                }
            }
        }

        &__logo-panel {
            background-color: $white;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            flex-direction: column;

            * {
                opacity: 1;
                animation-name: fadeIn;
                animation-iteration-count: 1;
                animation-timing-function: ease-in;
                animation-duration: 0.8s;
            }

            &__text {
                font-family: $primary-font-family;
                font-size: 80px;
                color: $primary-color;
            }
            
            &__logo {
                width: 70%;
                fill: $primary-color;
            }
        }

        &__address {
            background-color: $primary-color;
            grid-area: address;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            * {
                opacity: 1;
                animation-name: fadeIn;
                animation-iteration-count: 1;
                animation-timing-function: ease-in;
                animation-duration: 1.2s;
            }

            &__svg {
                width: 120px;
                fill: $white;
                &:hover {
                    fill: $sagey-grey;
                }
            }
            &__text {
                color: $white;
                font-family: $secondary-font-family;
                font-size: 16px;
                &:hover {
                    color: $sagey-grey;
                }
            }
        }
    }
}

a {
    text-decoration: none;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@media only screen and (min-aspect-ratio: 1) {
    .contact-us {
        &__content {
            &__logo-panel {
                border-left: double 6px $primary-color;
                border-right: double 6px $primary-color;
            }
        }
    }
}

@media only screen and (min-aspect-ratio: 1) and (max-width: 1300px) {
    .contact-us {
        &__content {
            &__logo-panel {
                &__text {
                    font-size: 70px;
                }
            }
        }
    }
}

@media only screen and (min-aspect-ratio: 1) and (max-width: 1100px) {
    .contact-us {
        &__content {
            &__email {
                &__svg {
                    width: 70px;
                }
                &__text {
                    font-size: 17px;
                }
            }
            &__logo-panel {
                &__text {
                    font-size: 60px;
                }
            }
            &__address {
                &__svg {
                    width: 70px;
                }
                &__text {
                    font-size: 17px;
                }
            }
        }
    }
}

@media only screen and (min-aspect-ratio: 1) and (max-width: 950px) {
    .contact-us {
        &__content {
            &__email {
                &__svg {
                    width: 70px;
                }
                &__text {
                    font-size: 15px;
                }
            }

            &__logo-panel {
                &__text {
                    font-size: 50px;
                }
            }
            
            &__address {
                &__svg {
                    width: 70px;
                }
                &__text {
                    font-size: 15px;
                }
            }
        }
    }
}

@media only screen and (min-aspect-ratio: 1) and (max-width: 950px) {
    .contact-us {
        &__content {
            &__email {
                &__svg {
                    width: 50px;
                }
                &__text {
                    font-size: 12px;
                }
            }
            &__logo-panel {
                &__text {
                    font-size: 35px;
                }
            }
            
            &__address {
                &__svg {
                    width: 50px;
                }
                &__text {
                    font-size: 12px;
                }
            }
        }
    }
}

@media only screen and (max-aspect-ratio: 1) {
    .contact-us {
        &__content {
            grid-template-areas: "email"
                "logo"
                "address"
                "footer";
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr 1fr 106px;

            &__email {
                &__svg {
                    width:50px;
                }
                &__text {
                }
            }

            &__logo-panel {
                border-top: double $primary-color 6px;
                border-bottom: double $primary-color 6px;

                &__text {
                }
                
                &__logo {
                    display: none;
                }
            }

            &__address {
                
                &__svg {
                    width: 50px;
                }
                &__text {
                    
                }
            }
        }
    }
}

@media only screen and (max-aspect-ratio: 1) and (max-width: 450px) {
    .contact-us {
        &__content {
            &__logo-panel {
                &__text {
                    font-size: 65px;
                }
            }
        }
    }
}

@media only screen and (max-aspect-ratio: 1) and (max-width: 350px) {
    .contact-us {
        &__content {
            &__logo-panel {
                &__text {
                    font-size: 45px;
                }
            }
            &__address {
                &__text {
                    font-size: 13px;
                }
            }
            &__email {
                &__text {
                    font-size: 14px;;
                }
            }
        }
    }
}

@media only screen and (max-aspect-ratio: 1) and (max-height: 700px) {
    .contact-us {
        &__content {
            &__email {
                &__text {
                    font-size: 13px;
                }
                &__svg {
                    padding-bottom: 5px;
                }
            }

            &__address {
                &__text {
                    font-size: 13px;
                }
            }
        }
    }
}