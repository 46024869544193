@use "../../assets/sass/variables" as *;
@use "../../assets/sass/mixins" as *;
@use "../../assets/sass/reset" as *;

.about-us {
    display: flex;
    flex-direction: column;
    background-color: $white;
    padding:40px;
    padding-top: 50px;
    padding-left: 80px;
    z-index: 2;
    position: relative;


    &__container {
        margin: 40px;
        margin-top:30px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;

        &__text {
            font-family: $secondary-font-family;
            font-size: 15px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            * {
                padding: 14px;
            }
        }
    }
}


@media only screen and ((max-width: 1100px)) {
    .about-us {
        padding: 10px;
        &__container {
            flex-direction: column;

            &__text{
                font-size: 13px;
                width: 100%;
                * {
                    padding-top: 20px;
                }               
            }
        }
    }
}

