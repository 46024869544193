@use "../../assets/sass/variables" as *;
@use "../../assets/sass/mixins" as *;
@use "../../assets/sass/reset" as *;

.img {
    opacity: 0;
    transition: all 1s ease;
    transition-delay: 0.6s;
    &--visible {
        opacity: 1;
    }
}

