@use "../../assets/sass/variables" as *;
@use "../../assets/sass/mixins" as *;
@use "../../assets/sass/reset" as *;

.member {
    display: flex;
    flex-direction: column;
    padding:40px;
    padding-left: 80px;
    z-index: 2;
    position: relative;
    color:white;

    &__heading {
        font-family: $primary-font-family;
        margin-top: 10px;
        margin-bottom: 40px;
        font-size: 35px;
    }


    &__container {
        margin: 20px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;

        &__img {
            width: 35vw;
            justify-self: left;
        }

        &__text {

            font-family: $secondary-font-family;
            width: 45%;
            font-size: 15px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            * {
                padding:10px;
            }
        }
        &--visible {
            opacity: 1;
        }
    }
}




@media only screen and ((max-width: 850px))  {
    .member {
        padding: 10px;
        &__heading {
            font-size: 23px;
            margin-bottom: 0px;
        }
        
        &__container {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: center;
            flex-direction:column;
        
            &--swapped {
                flex-direction: column-reverse;
            }

            &__img{
                width: 90%;
            }

            &__text{
                
                font-size: 13px;
                width: 100%;
                padding-bottom: 20px;
                * {
                    padding-top: 20px;
                } 
            }
        }
    }
}


@media only screen and ((max-width: 850px)) and ((min-width: 600px)){
    .member {
        &__container {
            &__img {
                width: 60%;
            }
        }
    }
}

@media only screen and ((min-width: 1250px)){
    .member {
        &__container {
            &__text {
                font-family: $secondary-font-family;
                width: 35%;
            }
        }
    }
}