@font-face {
    font-family: 'copperplate-gothic';
    src: url("../fonts/CopperplateGothicBoldRegular.ttf") format("truetype");
 }
 @font-face {
    font-family: 'CA-saygon-light';
    src: url("../fonts/CASaygonText-Light.ttf") format("truetype");
 }
 @font-face {
   font-family: 'CA-saygon-thin';
   src: url("../fonts/CASaygonText-Thin.ttf") format("truetype");
}
//colors
$primary-color: rgb(27, 51, 43);//rgb(42, 65, 41);
$secondary-color: rgb(58, 107, 89);
$white: rgb(243, 243, 231);
$sagey-grey: rgb(202, 218, 204);
$black: black;


//fonts
$primary-font-family: copperplate-gothic;
$secondary-font-family: CA-saygon-light;
$secondary-font-family-thin: CA-saygon-thin;

