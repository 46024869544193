@use "../../assets/sass/variables" as *;
@use "../../assets/sass/mixins" as *;

* {
    margin: 0px;
    padding: 0px;   

    &::-webkit-scrollbar {
        height: 10px;
        width: 10px;
        &-track {
            background: $white;            
        }

        &-thumb {
            background-color: $primary-color;
            border: $white 2px solid;
            border-radius: 10px;
        }
    }
}

.App {
    background-color: $white;
}