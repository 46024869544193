@use "../../assets/sass/variables" as *;
@use "../../assets/sass/mixins" as *;
@use "../../assets/sass/reset" as *;

.intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $primary-color;
    color: $white;  
    padding: 30px;
    padding-top: 0px;

    &__container {
        width:70%;
        margin: 30px;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        align-items: center;
        font-family: $secondary-font-family;
        font-size: 16px;
        &__text {
            padding: 30px;
        }
    }
}

@media only screen and ((max-width: 780px)) {
    .intro {
        &__container {
            width: 90%;
            &__text {
            }
        }
    }
}

@media only screen and ((max-width: 560px)) {
    .intro {
        &__container {
            width: 100%;
            &__text {
            }
        }
    }
}