@use "../../assets/sass/variables" as *;
@use "../../assets/sass/mixins" as *;
@use "../../assets/sass/reset" as *;

.side-bar {
    background: $white;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
    color: $primary-color;
    border-right: double $primary-color 6px;
    display: grid;
    grid-template-rows: 90px 1fr 90px;
    font-family: $secondary-font-family;
    font-size: 13px;
    grid-area: sideBar;
    position: relative;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 8px;
    }

    &__subheading {
        font-weight: bold;
        padding: 10px;
        font-size: 24px;
        transition-delay: 0.4s;
        font-family: $primary-font-family;
    }

    &--dark {
        color: $white;
        background: $primary-color;
        border-right: double $secondary-color 6px;
    }
    
    &__container {
        padding: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
    }

    &__instruction{
        font-weight: bold;
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 100%;

        &__arrows {
            display: flex;
            justify-content: space-around;
            padding-bottom: 30px;
            padding: 15px;
            margin-left: 50px;
            margin-right: 50px;
            svg {
                height: 15px;
                fill:white;
                :hover {
                    fill: $sagey-grey;
                }
            }
        }
    }

    
}

@media only screen and (max-width: 700px) {
    .side-bar {
        z-index: 1;
        overflow-x: hidden;

        &--hidden {
            padding: 0px;
        }

        &__container {
            &__text {
                width: 284px;
            }
        }

        
    }
}

@media only screen and (max-width: 700px) and (min-width: 536px) {
    .side-bar {
        &__container {
            &__text {
                width: 426px;
            }
        }
    }
}