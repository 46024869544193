@use "../../assets/sass/variables" as *;
@use "../../assets/sass/mixins" as *;


.nav-button {
    font-size: 20px;
    font-family: $secondary-font-family;
    padding: 7px;
    text-align: center;
    z-index: 3;
    text-decoration: none;
    color: $primary-color;

    &--current {
        font-weight: bold;

        &:hover {
            opacity: 100% !important;
        }
    }
    
    &:hover {
        opacity: 70%;
    }
}