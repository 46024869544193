@use "../../assets/sass/variables" as *;
@use "../../assets/sass/mixins" as *;

.nav {
    position: fixed;
    z-index: 4;
    width: 100vw;
    align-self: flex-start;
    align-items: center;
    height: 70px;
    display: grid;
    grid-template-columns: 140px 1fr;
    border-bottom: 6px $primary-color double;
    transition: all 0.75s ease;
    grid-area: nav;
    &--opaque {
        background-color: $secondary-color;
        border-bottom: 6px $primary-color double;
        
    }

    &__margin {
        margin-bottom: 70px;;
    }

    &__logo {
        height: 50px;
        fill: $primary-color;
        padding: 10px;
        transition: all 0.25s ease;

        &:hover {
            opacity: 70%;
        }
        &--opaque {
            fill: $white;
        }

        &--current:hover {
            opacity: 100%;
        }
    }

    &__bars {
        height: 50px;
        padding: 10px;
        fill: $primary-color;
        margin-left: auto;
        transition: all 0.75s ease;
        &--opaque {
            fill: $white;
        }
    }

    &__buttons {
        display: flex;
        justify-content: space-around;
        align-items: center;
        
        * {
            color: $primary-color;
        }
        &--opaque {
            * {
                color: $white;
            }
        }
    }
}

@media only screen and (min-width: 769px) {
    .nav {
        &__bars {
            display: none;
        }
    }
}

@media only screen and (max-width: 768px) {
    .nav {
        &__buttons {
            display: none;
        }
    }
}