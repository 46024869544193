@use "../../assets/sass/variables" as *;
@use "../../assets/sass/mixins" as *;
@use "../../assets/sass/reset" as *;

.container {
    margin: 0px;
    width: 90%;
    padding: 0px;
    padding-bottom: 15px;
    
    &__point {
        list-style-position: inside;
        opacity: 0;
        transition: all 1s ease;
        transition-delay: 0.4s;
        padding: 0px;
        padding-bottom: 8px; 
        
        &--visible {
            opacity: 1;
        }
    }
}


