@use "../../assets/sass/variables" as *;
@use "../../assets/sass/mixins" as *;
@use "../../assets/sass/reset" as *;

.interiors {
    display: grid;
    grid-template-columns: 350px 1fr;
    grid-template-rows: 76px 1fr;
    height:100vh;
    max-height: -webkit-fill-available;
    transition: 1s;

    grid-template-areas: 
    "nav nav"
    "sideBar content";
    background-color: $white;

    &__content {
        overflow-y: scroll;
        grid-area: content;
    }

    &__sidebar-button {
        display: none;
    }
}

@media only screen and (max-width: 700px) {
    .interiors {
        
        &__content {
            z-index: 0;
            overflow-x: hidden;
        }

        &--no-sidebar {
            grid-template-columns: 0px 1fr;
        }
        &__sidebar-button {
            z-index: 3;
            background-color: $white;
            border: double $primary-color 6px;
            position: absolute;
            bottom: 20vh;
            visibility: visible;
            height: 54px;
            width: 54px;
            fill: $primary-color;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
                height: 35px;
            }

            &--dark {
                border: double $primary-color 6px;
                background-color: $secondary-color;
                fill: $white;
            }
    
            &--hide {
                left: 314px;
                transition: 1s;
                transform: rotate(180deg);
                -webkit-transform: rotate(180deg);
            }
            &--show {
                left: 0px;
                transition: 1s;
                width: 44px;
                
            }
        }
    }
}

@media only screen and (max-width: 700px) and (min-width: 536px) {
    .interiors {
        grid-template-columns: 500px 1fr;
        
        &--no-sidebar {
            grid-template-columns: 0px 1fr;
        }

        &__sidebar-button {
            &--hide {
                left: 464px;
            }
        }
    }
}


@media only screen and (min-width: 1200px) {

    .interiors {
        grid-template-columns: 500px 1fr;
        transition: 0.5s all;
        &--dark {
            grid-template-columns: 350px 1fr; 
        }
    }

}