@use "../../assets/sass/variables" as *;
@use "../../assets/sass/mixins" as *;
@use "../../assets/sass/reset" as *;


.text {
    opacity: 0;
    transition: all 1s ease;
    transition-delay: 0.6s;
    &--visible {
        opacity: 1;
    }
    b {
        padding: 0px;
    }
}

p {
    padding-top: 3px;
    padding-bottom: 10px;
}

li {
    padding-bottom: 10px;
    list-style-position: inside;
}


