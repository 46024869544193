@use "../../assets/sass/variables" as *;
@use "../../assets/sass/mixins" as *;
@use "../../assets/sass/reset" as *;

.photo-popup {

    &__overlay{
        width: 100%;
        height: 100%;
        background-color: black;
        position: fixed;
        transition: all 0.75s ease;
        opacity: 80%;
        z-index: 1; 
        transition: all 1s ease;
    }

    &__close {
        height: 60px;
        padding: 20px;
        z-index: 3;
        fill: $white;
        margin-left: auto;
        transition: all 0.75s ease;
        position: fixed;
        right: 0px;

        &:hover {
            fill: $sagey-grey;
        }
    }
}



@media only screen and (max-width: 420px) {
    .photo-popup {
        &__close--hidden {
            opacity: 0%;
            transition: all ease 1s;
        }
    }
}
