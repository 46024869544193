@use "../../assets/sass/variables" as *;
@use "../../assets/sass/mixins" as *;

.popup {
    margin-top: 70px;
    position: fixed;
    display: flex;
    justify-content: center;
    width: 100vw;
    background-color: $sagey-grey;
    z-index: 3;
    height: 190px;
    transition: all 0.75s ease;

    &--clear {
        background-color: rgba(255, 255, 255, 0.397);
    }

    &__contents {
        justify-self: start;
        margin-bottom: none;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        border-bottom: 6px $primary-color double;
        width: 100%;
        padding-top: 5px;
        z-index: 6;
    }
}

@media only screen and (min-width: 769px) {
    .popup {
        display: none;
    }
}
