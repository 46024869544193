@use "../../assets/sass/variables" as *;
@use "../../assets/sass/mixins" as *;
@use "../../assets/sass/reset" as *;

.the-team {
    display: flex;
    flex-direction: column;
    background-color: $primary-color;
    padding:3%;
    padding-top: 2%;
    position: relative;
    z-index: 2;

    &__container {
        padding: 50px;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        align-items: center;
        &__img{
            height: 500px;
        }

        &__text{
            font-family: $secondary-font-family;
            width: 30%;
            color: $white;
        }
    }
}
