@use "../../assets/sass/variables" as *;
@use "../../assets/sass/mixins" as *;
@use "../../assets/sass/reset" as *;

.logo-panel {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;

    &__popups {
        z-index: 2;
    }

    &__img {
        z-index: 0;
    }

    &__main {
        height: 100%;
        width: 100%;
        z-index: 2;
        position: fixed;
        display: flex;
        justify-content: left;
        align-items: center;
        flex-direction: column;
    }

    &__logo {
        z-index: 3;
        fill: $primary-color;
        padding: 5px;
    }

    &__overlay {
        z-index: 2;
        background-color: rgb(217, 215, 215) ;
        opacity: 45%;
        position: absolute;
        width:100vw;
        height: 100vh;
    }
}

@media only screen and (min-aspect-ratio: 3/2) {
    .logo-panel {
        &__img {
            width: 100vw;
        }
        &__logo {
            height: 50vh;
        }
    }
}


@media only screen and ((max-width: 650px)  or (min-height: 800px)) {
    .logo-panel {
        &__logo {
            width: 70vw;
        }
    }
}

@media only screen and ((min-width: 650px))  {
    .logo-panel {
        &__logo {
            height: 40vh;
        }
    }
}
